<template>

    <div>

        <!--Claims Table-->
        <v-client-table style="width: 100%" ref="claimTable" :columns="columns" :data="claims" :options="tOptions" :theme="theme" class="dataTable">

            <b-form-checkbox slot-scope="props" slot="select" v-model="claimsSelected" :value="props.row._id" style="float: right"></b-form-checkbox>

            <div slot="h__select">
                <b-form-checkbox style="float: right" type="checkbox" @change="claimsSelected = unmarkAndMarkAll()"></b-form-checkbox>
            </div>

        </v-client-table>

    </div>

</template>

<script>
import Vue from 'vue';
import {ClientTable} from 'vue-tables-2';
import iss from "@/services/iss";
import claimsStore from "@/store/claimsStore";

Vue.use(ClientTable);

export default {
    name: "ClaimsTable",

    props: {
        editClaims: {type: Array},
        editBool: {type: Boolean}
    },

    data() {
        return {
            columns: ['select', 'description'],
            claims: [],
            claimsAllMarked: false,
            claimsSelected: [],
            theme: 'bootstrap4',
            tOptions: {
                orderBy: {column: 'description', ascending: true},
                headings: {
                    description: 'Description'
                },
                sortable: ['description'],
                filterable: ['description'],
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 10,
                skin: 'table table-striped table-hover',
            }
        }
    },

    created() {
        this.getClaims();
        if (this.editBool === true) {
            this.claimsSelected = this.editClaims;
        }
    },

    methods: {
        getClaims() {
            iss.getUserClaims().then(response => {
                this.claims = response.data;
            });
        },
        unmarkAndMarkAll() {
            if (this.claimsAllMarked === false) {
                let temp = [];
                this.$refs.claimTable.allFilteredData.forEach(function(item) {
                    temp.push(item._id);
                });
                this.claimsAllMarked = true;
                return temp;
            }
            else {
                this.claimsAllMarked = false;
                return ([]);
            }
        }
    },

    watch: {
        claimsSelected() {
            // update the currently selected claims in vuex store
            claimsStore.dispatch('updateClaimsSelected', this.claimsSelected);
        }
    }
}
</script>