import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({

    state: {
        claimsSelected: []
    },

    mutations: {
        UPDATE_CLAIMS(state, claims) {
            state.claimsSelected = claims;
        }
    },

    actions: {
        updateClaimsSelected({ commit }, claims) {
            commit('UPDATE_CLAIMS', claims);
        }
    },

    getters: {
        getSelectedClaims: (state) => () => {
            return state.claimsSelected;
        }
    }
});